import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import styled from 'styled-components'
import { rhythm } from '../utils/typography'
import Title from '../components/title'
import Subtitle from '../components/subTitle'
import SmallParagraph from '../components/smallParagraph'
import Socials from '../components/socials'
import { transparentize } from 'polished'
import Rect from '../components/rect'
import MediumParagraph from '../components/mediumParagraph'
import { FaAngleDown, FaAngleLeft } from 'react-icons/fa'
import { Link } from 'gatsby'
import BigTitle from '../components/bigTitle'

export const query = graphql`
	query($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				thumbnail_1
				thumbnail_2
				thumbnail_3
				title
				excerpt
				subtitle
				description
				tasks
				technologies
				year
			}
			id
		}
		allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(projects)/" } }) {
			edges {
				node {
					fields {
						slug
					}
					id
					frontmatter {
						title
					}
				}
			}
		}
	}
`

export default function Project({ data }) {
	const projects = data.allMarkdownRemark.edges.map(edge => edge.node)
	const currentProject = data.markdownRemark

	const [projectsAreOpen, setProjectsAreOpen] = useState(false)

	return (
		<Layout>
			<Link to={`/`}>
				<BackRect>
					<BackLabel>
						<FaAngleLeft />
						BACK
					</BackLabel>
				</BackRect>
			</Link>
			<ProjectsRect isOpen={projectsAreOpen} projectCount={projects.length}>
				<ProjectsMenu>
					<ProjectsList>
						{projects.map(project => {
							if (project.id !== currentProject.id)
								return (
									<Link to={`/project${project.fields.slug}`}>
										<ProjectLink key={project.id}> {project.frontmatter.title}</ProjectLink>
									</Link>
								)
						})}
					</ProjectsList>
					<CurrentProject onClick={() => setProjectsAreOpen(bool => !bool)}>
						{currentProject.frontmatter.title}
						<AnimatedIconDown isOpen={projectsAreOpen}>
							<FaAngleDown />
						</AnimatedIconDown>
					</CurrentProject>
				</ProjectsMenu>
			</ProjectsRect>
			<MainSection onClick={() => setProjectsAreOpen(false)}>
				<SocialsWrapper>
					<Socials />
				</SocialsWrapper>
				<ProjectTitle>{currentProject.frontmatter.title}</ProjectTitle>
				<Subtitle>{currentProject.frontmatter.subtitle}</Subtitle>
				<SmallParagraph>{currentProject.frontmatter.description}</SmallParagraph>
				<DetailsContainer>
					<DetailsCol>
						<DetailsTitle>Project Includes</DetailsTitle>
						{currentProject.frontmatter.tasks.map((task, i) => {
							return <DetailsItem key={i}>{task}</DetailsItem>
						})}
					</DetailsCol>
					<DetailsCol>
						<DetailsTitle>Technologies</DetailsTitle>
						{currentProject.frontmatter.technologies.map((tech, i) => {
							return <DetailsItem key={i}>{tech}</DetailsItem>
						})}
					</DetailsCol>
					<DetailsCol>
						<DetailsTitle>Year</DetailsTitle>
						<DetailsItem>{currentProject.frontmatter.year}</DetailsItem>
					</DetailsCol>
				</DetailsContainer>
				<ImageContainer>
					{currentProject.frontmatter.thumbnail_1 && currentProject.frontmatter.thumbnail_2 ? (
						<>
							<MobileImage src={currentProject.frontmatter.thumbnail_1} />
							<MobileImage src={currentProject.frontmatter.thumbnail_2} />
							{currentProject.frontmatter.thumbnail_3 && <MobileImage src={currentProject.frontmatter.thumbnail_3} />}
						</>
					) : (
						<DesktopImage src={currentProject.frontmatter.thumbnail_1} />
					)}
				</ImageContainer>
				<HorizontalLine />
				<MarkdownContent dangerouslySetInnerHTML={{ __html: currentProject.html }} />
			</MainSection>
		</Layout>
	)
}

const BackRect = styled(Rect)`
	position: fixed;
	z-index: 100;
	width: 15rem;
	height: 10rem;
	top: -7.5rem;
	left: -4rem;
	transform: rotate(5deg);
	cursor: pointer;

	:hover {
		transform: rotate(2deg);
		margin-top: 1rem;
	}
	transition: all 0.2s ease-in-out;

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		border-radius: 15px;
		left: -10rem;
	}
`

const BackLabel = styled.div`
	position: absolute;
	display: flex;
	align-items: center;
	font-family: ${props => props.theme.fonts.big};
	color: ${props => props.theme.colors.primary};
	padding-bottom: ${rhythm(0.5)};
	padding-right: ${rhythm(1)};
	bottom: 0;
	right: 0;

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		padding-bottom: ${rhythm(0.4)};
		padding-right: ${rhythm(0.8)};
	}
`

const ProjectsRect = styled(Rect)`
	position: fixed;
	z-index: 100;
	width: 16rem;
	height: 50rem;
	top: -47rem;
	right: 50vw;
	margin-right: -8rem;
	margin-top: ${props => (props.isOpen ? `${props.projectCount * 1.4}rem` : '0rem')};
	transition: all 0.2s ease-in-out;

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		border-radius: 15px;
		right: 2vw;
		margin-right: 0;
		width: 12rem;
	}
`

const ProjectsMenu = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding-left: ${rhythm(0.5)};
	padding-right: ${rhythm(0.5)};
	font-family: ${props => props.theme.fonts.big};
	color: ${props => props.theme.colors.primary};
	bottom: ${rhythm(0.5)};
`

const ProjectsList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`
const ProjectLink = styled(Title)`
	color: ${props => props.theme.colors.primary};
	font-size: ${rhythm(0.5)};
	padding: ${rhythm(0.2)} 0 ${rhythm(0.2)} 0;
	margin: 0;

	:hover {
		text-decoration: underline;
	}
`
const CurrentProject = styled(BigTitle)`
	display: flex;
	flex-direction: row;
	font-size: ${rhythm(0.5)};
	text-transform: none;
	margin-top: ${rhythm(0.5)};
	cursor: pointer;
`

const AnimatedIconDown = styled.span`
	display: flex;
	align-items: center;
	animation: TopDown 1s linear infinite;
	margin: 0 ${rhythm(0.2)} 0 ${rhythm(0.2)};
	transform: ${props => (props.isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
	transition: all 0.8s ease-in-out;

	@keyframes TopDown {
		0%,
		100% {
			margin-top: ${props => (props.isOpen ? '0' : rhythm(0.2))};
		}
		50% {
			margin-top: ${props => (props.isOpen ? '0' : '0')};
			margin-bottom: ${props => (props.isOpen ? rhythm(0.2) : '0')};
		}
	}
`

const MainSection = styled.div`
	padding: ${rhythm(4)} ${rhythm(2)} 0 ${rhythm(2)};

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		padding: ${rhythm(4)} 0 0 0;
	}
`

const SocialsWrapper = styled.div.attrs({
	'data-sal': 'slide-left',
	style: {
		'--sal-duration': '700ms'
	}
})`
	float: right;
	font-size: ${rhythm(1.3)};

	a {
		margin-right: 0;
		margin-left: ${rhythm(1)};
	}

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		font-size: 4vw;

		a {
			margin-left: 3vw;
		}
	}

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		display: none;
	}
`

const ProjectTitle = styled(Title).attrs({
	'data-sal': 'flip-up',
	style: {
		'--sal-duration': '750ms',
		'--sal-easing': 'ease'
	}
})`
	margin-bottom: ${rhythm(0.5)};
	margin-left: -${rhythm(0.05)};
	max-width: 50vw;

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		max-width: unset;
	}
`

const DetailsContainer = styled.div`
	margin-top: ${rhythm(1)};
	display: flex;
	flex-direction: row;

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		justify-content: space-between;
		padding-right: 20vw;
	}

	@media (max-width: ${props => props.theme.sizes.minMobile}) {
		padding-right: 5vw;
		font-size: 3.5vw;
	}
`
const DetailsTitle = styled(MediumParagraph)`
	margin-bottom: ${rhythm(0.3)};
`

const DetailsCol = styled.div`
	padding-right: ${rhythm(5)};
	display: flex;
	flex-direction: column;

	@media (max-width: ${props => props.theme.sizes.minDesktop}) {
		padding-right: 0;
	}
`
const DetailsItem = styled.p`
	padding: 0;
	margin: 0;
`

const ImageContainer = styled.div`
	margin-top: ${rhythm(1)};
	background-color: ${transparentize(0.95, 'black')};
	padding: 5vw;
	display: flex;
	justify-content: space-evenly;
`

const DesktopImage = styled.img`
	width: 100%;
	margin: 0;
`

const MobileImage = styled.img`
	width: 30%;
	margin: 0;
	align-self: flex-start;
`

const HorizontalLine = styled.div`
	background-color: ${props => props.theme.colors.secondary};
	margin-top: ${rhythm(1.5)};
	margin-bottom: ${rhythm(1.5)};
	height: 4px;
`

const MarkdownContent = styled.div`
	img {
		background-color: ${transparentize(0.95, 'black')};
		padding: 5vw;
	}

	a {
		color: unset;
		text-decoration: underline;
	}
`
